import React, {useContext, useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'

// Redux store is  provided to the React application at the top level using the <Provider> component from react-redux.
// This makes the store available to all components in the component tree, without passing it explicitly.
// You can then use useSelector to access the state in your components, and useDispatch to dispatch actions to the store.
import store from './app/kioskStore.js'
import KioskApp from "./app/KioskApp";
import AuthApp from "./app/AuthApp.jsx";
import { AuthProvider, AuthContext } from './app/context/AuthContext.jsx';

import './app/css/app.css';
// import {getValidToken} from "@/app/API/KioskApi.js";

const kioskAppDiv = document.getElementById("app");
const domainId = document.getElementById('app').getAttribute("data-domain_id");
const hostName = document.getElementById('app').getAttribute("data-host_name");
const welcomeMessage = document.getElementById('app').getAttribute("data-welcome_message");

const App = () => {
    // this is a global variable so we can track and reliably clear all timeouts
    window.timeoutIds = [];
    const { authenticated } = useContext(AuthContext);

    if (authenticated === null) {
        return <div>Loading...</div>;
    }
// console.log(authenticated);

    return authenticated ? (
        <KioskApp
            kioskDomainId={domainId}
            kioskHostName={hostName}
            welcomeMessage={welcomeMessage}
        />
    ) : (
        <AuthApp />
    );
};


const root = ReactDOM.createRoot(kioskAppDiv).render(
    <React.StrictMode>
        <Provider store={store}>
            <AuthProvider>
                <App />
            </AuthProvider>
        </Provider>
    </React.StrictMode>
);
