import React, {useState} from 'react';

import { FaClock } from "react-icons/fa";
import {FaCheckCircle} from "react-icons/fa";
import {LuMinusCircle} from "react-icons/lu";

import {maskEmail, maskName, formatTime, truncateString} from "@/app/Utilities/helpers.js";

import './CheckinTeamMember.css';
export const CheckinTeamMember = ({response, callCheckin, dupe = false, autoCheck = false}) => {

    const [checkinTemp, setCheckinTemp] = useState(autoCheck);

    const toggleCheckin = () => {
        setCheckinTemp(!checkinTemp);
        callCheckin(response, !checkinTemp);
    }

    return (
        <>
            <div className={"row"} onClick={(response.checkedIn || response.checkedOut || dupe) ? null : toggleCheckin}>

                {(response.checkedIn || response.checkedOut || dupe) && (
                    <div className="disabled-mask">
                        <span className={`${dupe && 'dupe'} checkin-details`}>
                            Already checked in
                            {dupe && (
                                <div>
                                    for {truncateString(dupe.need.need_title, 25)}
                                </div>
                            )}
                            {/*Checked in at {formatTime(response.todaysCheckin[response.todaysCheckin.length - 1].checkin_datetime_in)}*/}
                            {/*{response.todaysCheckin[0].checkin_datetime_out && (*/}
                            {/*        <>*/}
                            {/*            <br></br>*/}
                            {/*            Checked out at {formatTime(response.todaysCheckin.checkin_datetime_out)}*/}
                            {/*        </>*/}
                            {/*)}*/}
                        </span>
                    </div>
                )}
                <div className="col-2">

                    {(response.checkedIn || response.checkedOut || dupe) ? (

                        <>
                            {dupe ? (
                                <span className={"team-check-in-toggle dupe"}>
                                    <FaClock />
                                </span>
                            ) : (
                                <span className={"team-check-in-toggle in"}>
                                     <FaCheckCircle/>
                                </span>
                            )
                            }
                        </>

                    ) : (
                        <div>
                        {checkinTemp ? (
                                <span className={"team-check-in-toggle in-temp"}>
                                     <FaCheckCircle/>
                                </span>
                            ) : (
                                <span className={"team-check-in-toggle out"}>
                                     <LuMinusCircle/>
                                </span>
                            )}
                        </div>
                    )}


                </div>
                <div className="col-10">
                    <div className="text-title">{maskName(response.user.user_fname, response.user.user_lname)}</div>
                    <div>{maskEmail(response.user.user_email)}</div>
                </div>
            </div>
        </>
    )
}
