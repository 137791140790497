import React, {useState} from 'react';

import './ResponseItem.css';
import Avatar from "@/app/components/Avatar/Avatar.jsx";
import { maskEmail, maskName, formatTime } from "@/app/Utilities/helpers.js";
const ResponseItem = ({response, checkin, checkinTeam, checkout, checkoutTeam}) => {

    const handleClick = () => {

        if(response.checkedIn){
            if(response.team){
                checkoutTeam(response)
            }else{
                checkout(response);
            }
        }else{
            if(response.team){
                checkinTeam(response)
            }else{
                checkin(response);
            }
        }
    }

    return (
        <>
            <div className="row align-items-center response-item">
                <div className="col-md-1 col-2">
                    <Avatar user={response.user}></Avatar>
                </div>
                <div className="col-md-8 col-6">
                    <div className="mx-md-2">
                        <div className="text-title">
                            {maskName(response.user.user_fname, response.user.user_lname)}

                            {response.team && (
                                <>  -  {response.team.team_name}</>
                            )}
                        </div>
                        {maskEmail(response.user.user_email)}
                    </div>
                </div>
                <div className="col-md-3 col-4">

                    {/*{response.checkedOut ? (*/}
                    {/*    <span className={"checkin-details"}>*/}
                    {/*        Checked in at {formatTime(response.todaysCheckin.checkin_datetime_in)}*/}
                    {/*        <br></br>*/}
                    {/*        Checked out at {formatTime(response.todaysCheckin.checkin_datetime_out)}*/}
                    {/*    </span>*/}
                    {/*):(*/}
                        <button
                            className={`btn float-end align-middle ${response.checkedIn ? 'btn-checked-in' : 'btn-primary'}`}
                            onClick={handleClick}>{response.checkedIn ? 'Check Out' : 'This is me'}
                        </button>
                    {/*)}*/}

                </div>
            </div>

        </>
    )
}

export default ResponseItem;
