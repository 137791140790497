import React, {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectApiUrl} from "@/app/reducers/kioskSlice.js";
import {setResponseCheckedOut, selectResponses} from "@/app/reducers/responseSlice.js";

import axios from "axios";
import {__, lang, formatTime, getLocaleTimeZone} from "@/app/Utilities/helpers.js";

import { useReward } from 'react-rewards';
import {FaCheckCircle} from "react-icons/fa";

import './CheckoutTeamForm.css';

import SubmitButton from "@/app/Utilities/SubmitButton/SubmitButton.jsx";
import {CheckoutTeamMember} from "@/app/components/CheckoutTeamMember/CheckoutTeamMember.jsx";

import {ModalContext} from "@/app/context/ModalContext.jsx";
import {getValidToken} from "@/app/API/KioskApi.js";

const CheckoutTeamForm = ({responseId, callClose}) => {
    const apiUrl = useSelector(selectApiUrl);
    const dispatch = useDispatch();

    const [checkoutState, setCheckoutState] = useState(false);
    const [countDown, setCountDown] = useState(0);

    const responses = useSelector(selectResponses);
    const response = responses.find(resp => resp.id === responseId);

    useEffect(() => {
        tempCheckout(response, true);
    }, [responseId]);

    const getButtonLabel = () => {
        if(checkoutState){
            return "Close";
        }
        let teammateTerm = __("%s members", [lang('team')]);

        if(teamMembersToCheckout.length == 1){
            teammateTerm =  __("%s member", [lang('team')]);
        }
        return "Check out " + teamMembersToCheckout.length + " " + teammateTerm + " now";
    }

    const modalContext = useContext(ModalContext);

    const [successMessage, setSuccessMessage] = useState("");
    const { reward, isAnimating } = useReward('rewardId', 'confetti', {
        elementCount: 100,
        spread: 90,
    });

    const startCountDown = (seconds) => {
        setCountDown(seconds);
        const interval = setInterval(() => {
            setCountDown(countDown => countDown - 1);
        }, 1000);

        const timeout = setTimeout(() => {
            clearInterval(interval);
            callClose();
        }, seconds * 1000);

        modalContext.registerTimeout(timeout);
    }

    const postCheckout = async (response) => {

        try {
            const token = await getValidToken();

            const postdata = {
                teamResponse: teamMembersToCheckout,
                domainId: response.need.domain_id,
                local_timezone: getLocaleTimeZone()
            }
            const config = {
                headers: { 'Authorization': `Bearer ${token}` }
            }
            const checkoutResult = await axios.post(apiUrl + '/checkoutTeam',
                postdata,
                config
            );

            let teamMembersCheckedOut = checkoutResult.data.data;

            teamMembersCheckedOut.forEach(member => {
                dispatch(setResponseCheckedOut({
                    id: member.id,
                    checkedIn: false,
                    checkedOut: true,
                    todaysCheckin: member.todaysCheckin
                }));
            });

            let checkedOutString = teamMembersCheckedOut.length + " people checked out at ";
            if(teamMembersCheckedOut.length == 1){
                checkedOutString = teamMembersCheckedOut.length + " person checked out at ";
            }

            setSuccessMessage(checkedOutString + formatTime(checkoutResult.data.data[0].todaysCheckin[0].checkin_datetime_out, true));

            const newModalData = {
                modalStyle: "no-header",
                modalClass: "modal-success",
                modalTitle: "",
                modalSubtitle: ""
            }

            modalContext.updateModalData(newModalData);
            modalContext.updateResetToHome(true);

            setCheckoutState(true);
            reward();
            startCountDown(5);

        } catch (error) {
            console.error('Error fetching options:', error);
        }
    }

    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleSubmit = () => {

        if (isSubmitting){
            return;
        }
        setIsSubmitting(true);

        if(checkoutState){
            callClose();
            setIsSubmitting(false);
        }else{
            postCheckout(response).finally(() => setIsSubmitting(false));
        }
    }


    const teamResponses = useSelector(state => {
        const responses = selectResponses(state);

        return responses.filter(resp => {
            if(resp.team === null || (!resp.checkedIn && !resp.checkedOut)) return false;
            return response.team.id === resp.team.id;
        });
    });

    const [teamMembersToCheckout, setTeamMembersToCheckout] = useState([]);
    const tempCheckout = (response, toggleState) => {
        setTeamMembersToCheckout(prevMembers => {
            if (toggleState) {
                // If toggleState is true, attempt to add the member to the teamMembersToCheckin state
                if (!prevMembers.some(member => member.id === response.id)) {
                    // If the response does not exist, add it to the array
                    return [...prevMembers, response];
                }
                // If the response already exists, return the array unchanged
                return prevMembers;
            } else {
                // If toggleState is false, remove the member from the teamMembersToCheckout state
                return prevMembers.filter(member => member.id !== response.id);
            }
        });
    }

    // we track the user for who the team check in form was loaded so that user group select will remain consistent
    const teamRootResponse = (modalContext.rootResponse ? modalContext.rootResponse  : response);

    return (
        <>
            {successMessage ? (
                <>
                    <div className={"checkin-success text-center"}>
                        <FaCheckCircle className="checkin-success-icon"/>
                        <p className={"pt-3"}>{successMessage}</p>
                    </div>
                </>
            ) : (
                <>
                    <div className={"team-form-header"}>
                        <h2 className={"team-title mt-4 mb-3"}>{response.team.team_name}</h2>
                    </div>

                    <div className={"team-form-body"}>
                        {teamResponses.map((resp, index) => (
                            <div key={index} className="card team-member-card">
                                <CheckoutTeamMember
                                    response={resp}
                                    callCheckout={tempCheckout}
                                    autoCheck={resp.id == teamRootResponse.id}
                                ></CheckoutTeamMember>
                            </div>
                        ))}
                    </div>
                </>
            )}


            <div className={"team-form-footer"}>
                <div className="row">

                    {countDown > 0 &&
                        <div className="pt-3 text-center">This window will close in {countDown} seconds</div>
                    }

                    {successMessage === '' && (
                        <SubmitButton
                            label={getButtonLabel()}
                            onSubmitClick={handleSubmit}
                            isDisabled={teamMembersToCheckout.length < 1}
                        />
                    )}
                </div>
            </div>
            <div id="rewardId"/>
        </>
    )
}

export default CheckoutTeamForm;

