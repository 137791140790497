import React, {useContext} from "react";

import {ModalContext} from "@/app/context/ModalContext.jsx";

import FunctionButton from "@/app/Utilities/FunctionButton/FunctionButton.jsx";

const ModalFeedback = ({callClose}) => {

    const modalContext = useContext(ModalContext);
    const modalData = modalContext.modalData;

    const feedback = modalData.modalPassthrough.feedback;
    const feedbackParagraphs = feedback.split("\n");

    return (
        <>
            <p className={"text-center pt-3"}>

                {feedbackParagraphs.length > 1 ? (
                    <>
                        {feedbackParagraphs.map((paragraph, index) => {
                            return (
                                <p key={index}>{paragraph}</p>
                            )
                        })}
                    </>
                ) : (
                    <p>{feedback}</p>
                )}
            </p>

            <FunctionButton
                label={"Close"}
                onButtonClick={callClose}
            />
        </>
    )
}

export default ModalFeedback;
