import React from "react";

import Clock from "@/app/Utilities/Clock/Clock.jsx";
import AuthLogin from "@/app/components/AuthLogin/AuthLogin.jsx";
import Cookies from "js-cookie";

const AuthApp = () => {

    const handleResult = async (response) => {
        if(response.data.success){

            Cookies.set('kioskHash', response.data.kioskHash, { expires: 365, secure: true, sameSite: 'Strict' });

            window.location = "/";

        } else {
            // Handle error case
        }
    }

    return (
        <div className="container-xxl">
            <div className="utility-top">
                <div className="top-bar row pt-2 pb-2">
                    <div className={"col-md-12"}>
                        <section className={"pt-0"}>
                            <div className={"row"}>
                                <div className="col-md-3 text text-center-sm">
                                    <Clock></Clock>
                                </div>
                                <div className="col-md-6 text text-center">
                                    Galaxy Digital Kiosk/Check-In
                                </div>
                                <div className="col-md-3">
                                    {/*<ModeButton></ModeButton>*/}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <div className="main">
                <div className="row justify-content-center header-section">
                        <div className="col-md-12 p-0 header-image">
                                <img src="https://orion.galaxydigital.com/assets/images/banners/sustain-large.jpg" alt="Welcome to Galaxy Digital"/>
                        </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <section className={"content mt-5 login"}>
                            <AuthLogin handleResult={handleResult}
                            ></AuthLogin>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AuthApp;
