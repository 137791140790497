import React, {useContext, useEffect} from "react";

import {ModalContext} from "@/app/context/ModalContext.jsx";

import FunctionButton from "@/app/Utilities/FunctionButton/FunctionButton.jsx";

import QRCode from 'qrcode.react';
import {__, lang} from "@/app/Utilities/helpers.js";
import {useNavigate} from "react-router-dom";

const QualificationLink = ({callClose}) => {
    const modalContext = useContext(ModalContext);
    const modalData = modalContext.modalData;

    const QRLink = modalData.modalPassthrough.waiverLink;

    const navigate = useNavigate();

    // Navigate back to shifts view after 5 minutes
    useEffect(() => {

        const timeout = setTimeout(() => {
            callClose();
            navigate('/shifts');
        }, 300000); // 5 minutes

        modalContext.registerTimeout(timeout);

    }, []);

    return (
        <>
            <p className={"text-center"}>
                {__('Please scan the QR code to sign a %s and complete check in.', [lang('waiver')])}
            </p>

            <p className={"text-center"}>
                <QRCode value={QRLink} size={256} imageSettings={{height: 50, width: 50}}/>
            </p>

            <p className={"text-center"}>
                If you don't have a phone, please ask for help!
            </p>

            <FunctionButton
                label={"Close"}
                onButtonClick={callClose}
            />

            {/*{QRLink}*/}
        </>
    )
}

export default QualificationLink;
