import React, {useState} from 'react';

import {FaCheckCircle} from "react-icons/fa";
import {LuMinusCircle} from "react-icons/lu";

import './CheckoutTeamMember.css';

import { maskEmail, maskName, formatTime } from "@/app/Utilities/helpers.js";

export const CheckoutTeamMember = ({response, callCheckout, autoCheck = false}) => {

    const [checkoutTemp, setCheckoutTemp] = useState(autoCheck);

    let checkinIndex = (response.todaysCheckin.length > 0) ? response.todaysCheckin.length - 1: 0;

    const toggleCheckout = () => {
        setCheckoutTemp(!checkoutTemp);
        callCheckout(response, !checkoutTemp);
    }

    return (
        <>
            <div className={"row"} onClick={response.checkedIn ? toggleCheckout : null}>
                {(response.checkedOut) && (
                    <div className="disabled-mask">
                        <span className={"checkin-details"}>
                            Checked in at {formatTime(response.todaysCheckin[checkinIndex].checkin_datetime_in)}
                            {response.todaysCheckin[checkinIndex].checkin_datetime_out && (
                                <>
                                    <br></br>
                                    Checked out at {formatTime(response.todaysCheckin[checkinIndex].checkin_datetime_out)}
                                </>
                            )}
                        </span>
                    </div>
                )}

                <div className="col-2">
                    {response.checkedOut ? (
                        <span className={"team-check-out-toggle in"}>
                             <FaCheckCircle/>
                        </span>
                    ) : (
                        <div>
                            {checkoutTemp ? (
                                <span className={"team-check-out-toggle in-temp"}>
                                     <FaCheckCircle/>
                                </span>
                            ) : (
                                <span className={"team-check-out-toggle out"}>
                                     <LuMinusCircle/>
                                </span>
                            )}
                        </div>
                    )}


                </div>
                <div className="col-10">
                    <div className="text-title">{maskName(response.user.user_fname, response.user.user_lname)}</div>
                    <div>{maskEmail(response.user.user_email)}</div>
                </div>
            </div>
        </>
    )
}
