// Modal.jsx
import React, {useContext, useEffect, useRef} from 'react';
import {ModalContext} from "@/app/context/ModalContext.jsx";

import { IoClose } from "react-icons/io5";
import { IoChevronBack } from "react-icons/io5";

import './Modal.css';

const Modal = ({ isOpen, onClose, children,
                   modalClass= "",
                   modalStyle= "",
                   modalTitle= "", modalSubTitle= [],
                   backButton= {state: false, backButtonFunction: null}
}) => {

        const modalContext = useContext(ModalContext);

        const handleClose = () => {
            onClose();
        };

        useEffect(() => {
            const handleEscapeKey = (event) => {
                if (event.key === 'Escape' && isOpen) {
                    handleClose();
                }
            };

            document.addEventListener('keydown', handleEscapeKey);

            return () => {
                document.removeEventListener('keydown', handleEscapeKey);
            };
        }, [isOpen]);

        const modalRef = useRef(null);
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                handleClose();
            }
        };

        useEffect(() => {
            if (isOpen) {
                document.addEventListener('mousedown', handleClickOutside);
                document.body.style.overflow = 'hidden';
            } else {
                document.removeEventListener('mousedown', handleClickOutside);
            }

            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
                document.body.style.overflow = 'unset';
            };

        }, [isOpen]);

        return (
            <>
                {isOpen && (
                    <div className="modal-overlay">
                        <div className="modal-wrapper">
                            <div className={`modal-container ${modalClass}`} ref={modalRef}>
                                {modalStyle === "no-header" ? (
                                    <>
                                        <div className="modal-header">
                                            <span className="icon icon-delete" onClick={handleClose}>
                                                <IoClose onClick={handleClose}/>
                                            </span>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="modal-header">

                                            {(modalStyle != 'no-back-button' && backButton.state) && (
                                                <span className="icon icon-back"
                                                      onClick={backButton.backButtonFunction}>
                                                    <IoChevronBack onClick={backButton.backButtonFunction}/>
                                                </span>
                                            )}

                                            <h2 className="mb-sm mt-0">{modalTitle}</h2>

                                            {modalSubTitle && modalSubTitle.map((str, i) => (
                                                <div key={i}>
                                                    {str}
                                                </div>
                                            ))}

                                            <span className="icon icon-delete" onClick={handleClose}>
                                                <IoClose onClick={handleClose}/>
                                            </span>

                                        </div>
                                    </>
                                )}

                                <div className="modal-body">
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
;

export default Modal;
