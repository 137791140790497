import React, {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectApiUrl, selectSettings} from "@/app/reducers/kioskSlice.js";
import {setResponseCheckedIn, selectResponses, setResponseCheckedOut} from "@/app/reducers/responseSlice.js";
import {selectShifts, shiftsReceived} from "@/app/reducers/shiftSlice.js";

import { useReward } from 'react-rewards';
import {FaCheckCircle} from "react-icons/fa";

import axios from "axios";
import {__, lang, formatTime, getLocaleTimeZone} from "@/app/Utilities/helpers.js";

import './CheckinTeamForm.css';

import SubmitButton from "@/app/Utilities/SubmitButton/SubmitButton.jsx";
import {CheckinTeamMember} from "@/app/components/CheckinTeamMember/CheckinTeamMember.jsx";

import {ModalContext} from "@/app/context/ModalContext.jsx";

import UserGroupSelect from "@/app/components/UserGroupSelect/UserGroupSelect.jsx";
import TeamManagementControls from "@/app/components/TeamManagementControls/TeamManagementControls.jsx";
import {getValidToken} from "@/app/API/KioskApi.js";

const CheckinTeamForm = ({responseId, callClose}) => {
    const apiUrl = useSelector(selectApiUrl);
    const shifts = useSelector(selectShifts);

    const dispatch = useDispatch();

    const [checkInState, setCheckInState] = useState(false);
    const [countDown, setCountDown] = useState(0);
    const [userGroups, setUserGroups] = useState([]);
    const [checkedInState, setCheckedInState] = useState(false);

    const responses = useSelector(selectResponses);
    const response = responses.find(resp => resp.id === responseId);

    useEffect(() => {
        duplicateCheckin();
    }, []);

    useEffect(() => {
        tempCheckin(response, true);
    }, [responseId]);

    const [dupeCheckinUsers, setDupeCheckinUsers] = useState([]);
    const [dupeResponseUserIds, setDupeResponseUserIds] = useState([]);

    const duplicateCheckin = async () => {
        const shiftIds = shifts.map(shift => shift.sch_id);

        // if there is only one shift then there is no need to check for duplicate checkin
        if (shiftIds.length <= 1) {
            setDupeCheckFinished(true);
            setDupeCheckin(false);

            return false;
        }

        const userIds = teamResponses.map(response => response.user.id);

        try {

            const token = await getValidToken();

            const postdata = {
                userIds: userIds,
                shiftIds: shiftIds,
                local_timezone: getLocaleTimeZone()
            }
            const config = {
                headers: { 'Authorization': `Bearer ${token}` }
            }
            const dupeResult = await axios.post(apiUrl + '/checkinDupeTeam',
                postdata,
                config
            );

            if(dupeResult.data){

                const dupeResultResponse = dupeResult.data.data;

                setDupeCheckinUsers(dupeResultResponse);
                setDupeResponseUserIds(dupeResultResponse.map(response => response.user.id));

            }else{

            }

        } catch (error) {
            console.error('Error fetching options:', error);
        }
    }

    const getButtonLabel = () => {
        if(checkInState){
            return "Close";
        }
        let teammateTerm = __("%s members", [lang('team')]);

        if(teamMembersToCheckin.length == 1){
            teammateTerm =  __("%s member", [lang('team')]);
        }
        return "Check in " + teamMembersToCheckin.length + " " + teammateTerm + " now";
    }

    const [userGroupsSelected, setUserGroupsSelected] = useState([]);
    useEffect(() => {
        setUserGroupsSelected(userGroups.filter(option => option.selected).map(({ id }) => id));

    }, [userGroups]);

    const [isTouched, setIsTouched] = useState(false);
    useEffect(() => {
        if(isTouched){
            validateUserGroups();
        }

    }, [userGroupsSelected, isTouched]);

    const modalContext = useContext(ModalContext);

    const [successMessage, setSuccessMessage] = useState("");
    const { reward, isAnimating } = useReward('rewardId', 'confetti', {
        elementCount: 100,
        spread: 90,
    });

    const startCountDown = (seconds) => {
        setCountDown(seconds);
        const interval = setInterval(() => {
            setCountDown(countDown => countDown - 1);
        }, 1000);

        const timeout = setTimeout(() => {
            clearInterval(interval);
            callClose();
        }, seconds * 1000);

        modalContext.registerTimeout(timeout);
    }

    const userGroupHourSettings = useSelector(state => selectSettings(state, 'user_group_required'));
    const [userGroupValid, setUserGroupValid] = useState(true);
    const validateUserGroups = () => {

        if(userGroupHourSettings == 2 && userGroups.length > 0 && userGroupsSelected.length == 0){
            setUserGroupValid(false);
            return false;
        }else{
            setUserGroupValid(true);
            return true;
        }
    }

    const postCheckin = async (response) => {

        if(userGroupValid == false){
            return;
        }

        const userGroupsSelected = userGroups.filter(option => option.selected).map(({id}) => id);

        try {
            const token = await getValidToken();

            const postdata = {
                teamResponse: teamMembersToCheckin,
                userGroups: userGroupsSelected,
                domainId: response.need.domain_id,
                local_timezone: getLocaleTimeZone()
            }
            const config = {
                headers: { 'Authorization': `Bearer ${token}` }
            }
            const checkinResult = await axios.post(apiUrl + '/checkinTeam',
                postdata,
                config
            );

            setCheckedInState(true);

            let teamMembersCheckedIn = checkinResult.data.data;

            teamMembersCheckedIn.forEach(member => {
                dispatch(setResponseCheckedIn({
                    id: member.id,
                    checkedIn: true,
                    checkedOut: false,
                    todaysCheckin: member.todaysCheckin
                }));
            });

            let checkedInString = teamMembersCheckedIn.length + " people checked in at ";
            if(teamMembersCheckedIn.length == 1){
                checkedInString = teamMembersCheckedIn.length + " person checked in at ";
            }

            setSuccessMessage(checkedInString + formatTime(checkinResult.data.data[0].todaysCheckin[0].checkin_datetime_in, true));

            const newModalData = {
                modalStyle: "no-header",
                modalClass: "modal-success",
                modalTitle: "",
                modalSubtitle: ""
            }

            modalContext.updateModalData(newModalData);
            modalContext.updateResetToHome(true);

            setCheckInState(true);
            reward();
            startCountDown(5);

        } catch (error) {
            console.error('Error fetching options:', error);
        }
    }

    const setSelectedUserGroups = (selectedGroups) => {
        setUserGroups(selectedGroups);
    }

    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleSubmit = () => {

        if (isSubmitting){
            return;
        }
        setIsSubmitting(true);

        if(!validateUserGroups()){
            setIsSubmitting(false);
            return;
        }

        if(checkInState){
            callClose();
            setIsSubmitting(false);
        }else{
            postCheckin(response).finally(() => setIsSubmitting(false));
        }
    }

    const teamResponses = useSelector(state => {
        const responses = selectResponses(state);

        return responses.filter(resp => {
            if(resp.team === null || resp.checkedIn == true) return false;

            return response.team.id === resp.team.id;
        });
    }).sort((a, b) => a.user.user_fname.localeCompare(b.user.user_fname));

    const updatedTeamResponses = teamResponses.map(response => {
        const responseCopy = { ...response }; // Create a shallow copy
        responseCopy.dupe = dupeResponseUserIds.includes(response.user.id);
        return responseCopy;
    }).sort((a, b) => a.dupe - b.dupe);

    const teamResponsesCheckedIn = useSelector(state => {
        const responses = selectResponses(state);

        return responses.filter(resp => {
            if(resp.team === null || resp.checkedIn == false) return false;

            return response.team.id === resp.team.id;
        });
    });

    const [teamMembersToCheckin, setTeamMembersToCheckin] = useState([]);
    const tempCheckin = (response, toggleState) => {
        setTeamMembersToCheckin(prevMembers => {
            if (toggleState) {
                // If toggleState is true, attempt to add the member to the teamMembersToCheckin state
                if (!prevMembers.some(member => member.id === response.id)) {
                    // If the response does not exist, add it to the array
                    return [...prevMembers, response];
                }
                // If the response already exists, return the array unchanged
                return prevMembers;
            } else {
                // If toggleState is false, remove the member from the teamMembersToCheckin state
                return prevMembers.filter(member => member.id !== response.id);
            }
        });
    }

    function getDupeCheckinUsers(userId){

        if(dupeCheckinUsers.length < 1){
            return false;
        }

        const dupeUser = dupeCheckinUsers.find(response => response.user.id == userId);

        return dupeUser;
    }

    // we track the user for who the team check in form was loaded so that user group select will remain consistent
    const teamRootResponse = (modalContext.rootResponse ? modalContext.rootResponse  : response);

    return (
        <>
            {successMessage ? (
                <>
                    <div className={"checkin-success text-center"}>
                        <FaCheckCircle className="checkin-success-icon"/>
                        <p className={"pt-3"}>{successMessage}</p>
                    </div>
                </>
            ) : (
                <>
                    <div className={"team-form-header"}>
                        <h2 className={"team-title mt-4 mb-3"}>{response.team.team_name}</h2>
                    </div>

                    <div className={"team-form-body"}>
                        {updatedTeamResponses.map((resp, index) => (
                            (!checkInState) && (
                                <div key={index} className="card team-member-card">

                                    <CheckinTeamMember
                                        dupe={getDupeCheckinUsers(resp.user.id)}
                                        response={resp}
                                        callCheckin={tempCheckin}
                                        autoCheck={resp.user.id == teamRootResponse.user.id}
                                    ></CheckinTeamMember>

                                </div>
                            )
                        ))}

                        {teamResponsesCheckedIn.map((response, index) => (

                            (!checkInState || (checkInState && response.checkedIn)) && (
                                <div key={index} className="card team-member-card">

                                    <CheckinTeamMember
                                        response={response}
                                        callCheckin={tempCheckin}
                                    ></CheckinTeamMember>

                                </div>
                            )
                        ))}
                    </div>
                </>
            )}

            <div className={"team-form-footer"}>
                {countDown == 0 && (
                    <>

                        <div className="mt-3">
                            <TeamManagementControls
                                team={response.team}
                                responseId={response.id}
                            ></TeamManagementControls>
                        </div>

                        <div className="mt-2">
                            {userGroupHourSettings > 0 && (
                                <UserGroupSelect
                                    userId={teamRootResponse.user.id}
                                    needId={response.need.id}
                                    teamId={response.team.id}
                                    updateSelected={setSelectedUserGroups}
                                    checkedInState={checkedInState}
                                    isIvalid={userGroupValid}
                                    setIsTouched={setIsTouched}
                                ></UserGroupSelect>
                            )}
                        </div>
                    </>
                )}

                <div className="row">
                    {countDown > 0 &&
                        <div className="pt-3 text-center">This window will close in {countDown} seconds</div>
                    }
                </div>

                {successMessage === '' && (
                    <div className="mt-4">
                        <SubmitButton
                            label={getButtonLabel()}
                            onSubmitClick={handleSubmit}
                            isDisabled={teamMembersToCheckin.length < 1}
                        />
                    </div>
                )}
            </div>
            <div id="rewardId"/>
        </>
    )
}

export default CheckinTeamForm;

