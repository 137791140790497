import { createSlice } from '@reduxjs/toolkit';

export const responseSlice = createSlice({
    name: 'responses',
    initialState: {
        loading: 'idle',
        responses: []
    },
    reducers: {
        responsesReceived: (state, action) => {
            state.responses = action.payload
        },
        addResponseToShift: (state, action) => {

            const existingResponse = state.responses.find(response => response.id == action.payload.response.id);

            if(existingResponse){
                return;
            }else{
                state.responses.push(action.payload.response);
            }
        },
        updateTeamResponses: (state, action) => {
            state.responses.forEach(response => {
                if(response.team && response.team.id == action.payload.response.team.id){
                    response.team = action.payload.response.team;
                }
            });
        },
        setResponseCheckedIn: (state, action) => {
            const {id, checkedIn, checkedOut, todaysCheckin} = action.payload;

            const existingResponse = state.responses.find(response => response.id == id);

            if (existingResponse) {
                existingResponse.checkedIn = checkedIn;
                existingResponse.checkedOut = checkedOut;
                existingResponse.todaysCheckin = todaysCheckin;
            }

            // reset any shift search term when a response is checked in
            localStorage.setItem('shiftSearchTerm', "");
        },
        setResponseCheckedOut: (state, action) => {
            const {id, checkedIn, checkedOut, todaysCheckin} = action.payload;

            const existingResponse = state.responses.find(response => response.id == id);

            if (existingResponse) {
                existingResponse.checkedIn = checkedIn;
                existingResponse.checkedOut = checkedOut;
                existingResponse.todaysCheckin = todaysCheckin;
            }

            // reset any shift search term when a response is checked in
            localStorage.setItem('shiftSearchTerm', "");
        },
        updateResponse: (state, action) => {
            const response = action.payload;

            const existingResponse = state.responses.find(resp => resp.id == response.id);

            if (existingResponse) {
                existingResponse.team = response.team;
            }
        },
        clearAllResponsesCheckedOut: (state) => {
            state.responses.forEach(response => {
                response.checkedOut = false;
            });
        },
    },
});

export const {
    addResponseToShift,
    setResponseCheckedIn,
    setResponseCheckedOut,
    updateResponse,
    updateTeamResponses,
    clearAllResponsesCheckedOut,
    responsesReceived
} = responseSlice.actions;

export const selectResponses = (state) => state.response.responses;

// export const selectResponseById = (state, shiftId) => {
//     let selectedResponse =  state.shift.shifts.find(shift => shift.sch_id == shiftId);
//     return selectedResponse;
// }

export default responseSlice.reducer;
